<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'quarter'}" v-on:click="setTempPeriod('quarter')" ref="view_type_quarter"  data-toggle="pill" data-period="quarter">{{ $t('period.quarter') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in years" v-bind:key="index" :value="item"
                            :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'quarter'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filQuarter" id="quarter" name="quarter" ref="quarter" >
                          <option v-for="(quarter, index) in $util.quarters()" v-bind:key="index" :value="index"
                            :selected="filters.filQuarter === parseInt(index)" >{{ quarter }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.quarter') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in $util.months()" v-bind:key="index" :value="index"
                            :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operation / Modal -->
              <h6 class="mb-3 mt-4">{{ $t('operation type') }}</h6>
              <div class="row">
                <!-- Filter Operation  -->
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Importação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.impo}">
                        <input id="operation_impo" value="2" v-on:click="validaFiltro"
                          type="checkbox" ref="operation_impo" :disabled="disable.impo"
                          :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Exportação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.impo}">
                        <input id="operation_expo" value="1" :disabled="disable.impo"
                          v-on:click="validaFiltro" type="checkbox" ref="operation_expo"
                          :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>

      <!-- Tarifários cadastrados com mais de 7 dias -->
      <div class="col-12 col-sm-6 col-md-4">
        <custom-summary-box title="Agendamentos com 7 dias ou mais de validade" :value="reportData.summary.tariffSevenPlusValidity.value"
          :percent="reportData.summary.tariffSevenPlusValidity.percent"
          :description="'Valor atual, não se aplica o filtro de data'"
        />
      </div>

      <!-- Clientes possuem tarifários ativos -->
      <div class="col-12 col-sm-6 col-md-4">
        <custom-summary-box title="Clientes possuem tarifários ativos" :value="reportData.summary.clientsWitchScheduleActive.value"
          :percent="reportData.summary.clientsWitchScheduleActive.percent"
          :description="'Valor atual, não se aplica o filtro de data'"
        />
      </div>

      <!-- Clientes que receberam tarifário no período -->
      <div class="col-12 col-sm-6 col-md-4">
        <summary-box title="Clientes que receberam tarifário no período" :value="reportData.summary.clientsSending.value"
          :percent="reportData.summary.clientsSending.percent"
          :description="getDescription(reportData.summary.clientsSending.value, reportData.summary.clientsSending.previous, 'clientes no período')"
        />
      </div>

      <!-- Tarifários enviados no período -->
      <div class="col-12 col-sm-6 col-md-4">
        <summary-box title="Tarifários enviados no período" :value="reportData.summary.tariffSentPeriod.value"
          :percent="reportData.summary.tariffSentPeriod.percent"
          :description="getDescription(reportData.summary.tariffSentPeriod.value, reportData.summary.tariffSentPeriod.previous, 'tarifários enviados no período')"
        />
      </div>

      <!-- Tarifários enviados no período -->
      <div class="col-12 col-sm-6 col-md-4">
      </div>

      <!-- Tarifários enviados no período -->
      <div class="col-12 col-sm-6 col-md-4">
      </div>

      <!-- Tarifários que estão sendo enviados -->
      <div class="col-12 col-md-12 report-content mt-0">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('report.tariff_indicators.sent') }}</h5>

            <!-- Table -->
            <div class="table-responsive ">
              <vuetable ref="vuetable"
                :noDataTemplate="this.$i18n.t('noting to result')"
                :api-url="sentEndpoint"
                :fields="vSentTableFields"
                :sort-order="sentSortOrder"
                :css="css.table"
                pagination-path="pagination"
                :per-page="10"
                :multi-sort="multiSort"
                :append-params="filter"
                multi-sort-key="shift"
                :http-options="httpOptions"
                track-by="id"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
                @vuetable:load-error="handleLoadError"
                @vuetable:cell-clicked="onRowClicked">
              </vuetable>
            </div>
            <vuetable-pagination ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination><!-- END Table -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import SummaryBox from '@/components/Basic/SummaryBox'
import CustomSummaryBox from '@/components/Basic/CustomSummaryBox'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import ReportService from '@/services/ReportService'

export default {
  name: 'TariffScheduleUsageSent',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.tariff_indicators.title') + ' - %s'
    }
  },
  data () {
    return {
      reportData: {
        data: [],
        summary: {
          tariffSevenPlusValidity: {
            value: 0,
            percent: 0,
            previous: 0
          },
          clientsWitchScheduleActive: {
            value: 0,
            percent: 0,
            previous: 0
          },
          clientsSending: {
            value: 0,
            percent: 0,
            previous: 0
          },
          tariffSentPeriod: {
            value: 0,
            percent: 0,
            previous: 0
          }
        }
      },
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      sentEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task/tariffs/sent',
      tempPeriod: 'monthly',
      isLoadingTable: true,
      isLoadingCards: true,
      fullPage: true,
      sentTable: [
        {
          name: 'cliente',
          title: 'Cliente',
          sortField: 'p.nome',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        },
        {
          name: 'vendedor',
          title: 'Vendedor',
          sortField: 'ven.nome',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        },
        {
          name: 'usuario',
          title: 'Criado por',
          sortField: 'user.email',
          formatter: (value) => this.$util.formatUser(value, undefined, ' - ')
        },
        {
          name: 'quantidade',
          title: 'Enviados',
          sortField: 'count(u.id)',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      sentSortOrder: [
        { field: 'count(u.id)', direction: 'desc' }
      ],
      activeFilterList: {
        usuario: null,
        vendedor: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {},
      multiSort: true,
      httpOptions: {
        headers: {
          Authorization:
            'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      disable: {
        expo: false,
        impo: false
      }
    }
  },
  components: {
    Loading,
    FilterSideBar,
    FilterContainer,
    Vuetable,
    VuetablePagination,
    SummaryBox,
    CustomSummaryBox
  },
  beforeMount () {
    this.isLoadingTable = true
    this.isLoadingCards = true
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    Date.prototype.getWeek = function (dowOffset) {
      // default dowOffset to zero
      dowOffset = dowOffset !== undefined && typeof (dowOffset) === 'number' ? dowOffset : 0
      let newYear = new Date(this.getFullYear(), 0, 1)
      // the day of week the year begins on
      let day = newYear.getDay() - dowOffset
      day = (day >= 0 ? day : day + 7)
      let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
      let weeknum
      // if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1)
          let nday = nYear.getDay() - dowOffset
          nday = nday >= 0 ? nday : nday + 7
          // if the next year starts before the middle of the week, it is week #1 of that year
          weeknum = nday < 4 ? 1 : 53
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7)
      }
      return weeknum
    }
    this.filters.filCliente = []
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    // if (this.user) {
    this.getReportStatementData()
    // }

    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    getTitle () {
      let title = ''

      if (this.filters.filPeriodo === 'monthly') {
        title = 'RESULTADO MENSAL'
      } else if (this.filters.filPeriodo === 'quarter') {
        title = 'RESULTADO TRIMESTRAL'
      } else if (this.filters.filPeriodo === 'yearly') {
        title = 'RESULTADO ANUAL'
      }

      return title
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getPercentMark (value) {
      let goalOnPeriod = 1
      let percentMark = 100

      if (value.goal > 0) {
        goalOnPeriod = value.goal * 1.0
      }
      percentMark = (value.goal * 100 / goalOnPeriod)

      return percentMark
    },
    getDescription (current, previous, label, prefix) {
      let lessThanInfo = 'na semana anterior.'
      let equalInfo = 'a semana anterior.'

      if (this.filter.period === 'yearly') {
        lessThanInfo = 'no ano anterior.'
        equalInfo = 'o ano anterior.'
      } else if (this.filter.period === 'monthly') {
        lessThanInfo = 'no mês anterior.'
        equalInfo = 'o mês anterior.'
      }

      let diferenca = current - previous
      prefix = prefix || ''
      if ((diferenca) > 0) {
        return prefix + this.showNumber(diferenca) + ' ' + label + ' a mais que ' + lessThanInfo
      } else if ((diferenca) === 0) {
        return prefix + ' ' + label + ' iguais ' + equalInfo
      } else {
        return prefix + this.showNumber(diferenca * -1.0) + ' ' + label + ' a menos que ' + lessThanInfo
      }
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))
      return quarter
    },
    getPercentualInsuranceGoal (totalFiles, percent) {
      let totalPercent = 0

      if (totalFiles) {
        totalPercent = totalFiles * percent / 100
      }

      return totalPercent
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      // this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
      this.getReportStatementData()
    },
    resetFilter (event) {
      this.hideQuickSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      // if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
      //   // Se usuário for um vendedor filtro pelo seu usuário ao entrar no sistema
      //   if (this.isSeller) {
      //     this.filters.filEmployee = this.$store.getters.userInfo.active_employee.id_employee
      //   } else {
      //     // Senão filtro pelo primeiro vendedor da lista
      //     this.filters.filEmployee = this.sellerList[0].id_employee
      //   }
      // }

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
    },
    validaFiltro () {
      let contOp = 0

      contOp = this.$refs.operation_expo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_impo.checked ? (contOp + 1) : contOp

      this.disable.expo = false
      this.disable.impo = false

      if (contOp === 1) {
        switch (true) {
          case this.$refs.operation_expo.checked:
            this.disable.expo = true
            break
          case this.$refs.operation_impo.checked:
            this.disable.impo = true
            break
        }
      }
    },
    getReportStatementData () {
      let me = this
      me.isLoadingCards = true

      ReportService.getStatementTariffSent(this.filter).then(res => {
        me.reportData = res.data

        me.isLoadingCards = false
      })
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (thousandsSep !== '') {
        thousandsSep = thousandsSep || '.'
      }

      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showIntegerGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showInteger(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    asset (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      return baseUrl + value
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }
      CustomerService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
      })
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClicked (row) {

    },
    onLoading () {
      this.isLoadingTable = true
    },
    onLoaded () {
      this.isLoadingTable = false
    },
    handleLoadError (response) {
    }
  },
  computed: {
    years () {
      return this.$util.filterYearsOptions()
    },
    getActiveFilterList () {
      let activeFilterList = {
        // usuario: null,
        // vendedor: null,
        month: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      } else if (this.filters.filPeriodo === 'yearly') {
        activeFilterList.year = null
      }

      return activeFilterList
    },
    vSentTableFields () {
      return this.sentTable
    },
    isDirector () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_DIRECTOR') !== -1) {
        return true
      }
      return false
    },
    isFinancial () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_FINANTIAL') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSeller () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        if (userInfo.active_employee) {
          if (userInfo.active_employee.is_seller) {
            return true
          }
        }
      }
      return false
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_COMERCIAL_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SALES_COORDINATOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_REPRESENTS_ALAN_AS_SELLER').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    totalFiles () {
      let total = 0

      let reportData = this.reportData
      if (reportData.details) {
        total = (reportData.details.fcl.import.boarded.files.current +
        reportData.details.fcl.export.boarded.files.current +
        reportData.summary.air.current +
        reportData.summary.maritime.lcl.current)
      }

      return total
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isLoading () {
      return this.isLoadingTable || this.isLoadingCards
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .card-body {
    padding: 0.5rem 1.8rem;
  }

  @media (max-width: 576px) {

    .card-body {
      padding: 0.8rem 0.2rem;
    }

    .gray-box {
      padding: 0.8rem 0.2rem;
    }
  }

  .dark-gray-box {
    background-color: #e3e2e1;
    border-radius: 0 0 38px 38px;
    margin-bottom: 10px;
    padding: 25px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 1px;
  }

  .dark-gray-box .line {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 27px;
  }

  .label {
    padding-left: 47px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-position: center left;
    background-repeat: no-repeat;
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;
  }

  .label.air {
    background-image: url("/static/images/report/air_icon.png");
  }

  .label.ship {
    background-image: url("/static/images/report/ship_icon.png");
  }

  .label.lcl {
    background-image: url("/static/images/report/lcl_icon.png");
  }

  .label.fcl {
    background-image: url("/static/images/report/fcl_icon.png");
  }

  .label.insurance {
    background-image: url("/static/images/report/insurance_icon.png");
  }

  .label.green {
    background-image: url("/static/images/report/green_icon.png");
    padding-left: 20px;
  }

  .label.yellow {
    background-image: url("/static/images/report/yellow_icon.png");
    padding-left: 20px;
  }

  .label.red {
    background-image: url("/static/images/report/red_icon.png");
    padding-left: 20px;
  }

  .card-legend, .card-title {
    color: #84754E;
    text-align: center;
    font-size: 18px;
  }

  .card-title, .card-title b {
    margin-top: 45px;
    margin-bottom: 0;
    font-size: 19px;

  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }

  .gray-box {
    background-color: #F3F1ED;
    border-radius: 38px 38px 0 0;
    margin-top: 82px;
  }

  /* Sellect Section */
  .row.seller-box {
    margin-bottom: -100px;
  }

  .seller {
    text-align: center;
    top: -100px;

  }

  .seller img {
    border: 10px solid #F3F1ED;
    border-radius: 148px;
    width: 148px;
    position: relative;
    overflow: hidden;
  }

  .seller h2 {
    margin: 12px 0;
    font-size: 25px;
  }

  .summary-box {
    margin-top: 39px;
  }

  /* Message box */
  .message-box {
    margin: 0 2.8rem;
    color: #84734C;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .message-box {
      margin: 0 0.8rem;
    }
  }

  /* Details */
  .detail-box .indicator-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 50px 60px;
  }

  /* Details */
  .detail-box .content-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 20px 20px;
  }
  .detail-box .content-box .card-title {
    margin-top: 15px;
  }

  .detail-box.first .indicator-box {

    border-radius: 40px 40px 0 0;
    padding: 0 60px;
    padding-top: 50px;
  }

  .detail-box.middle .indicator-box {

    border-radius: 0 0 0 0;
    padding: 0 60px;
    padding-bottom: 65px;
  }

  .detail-box.first {
    margin-top: 70px;

  }
  .detail-box.last .indicator-box, .detail-box.simple .indicator-box {
    padding-bottom: 45px;

  }

  .detail-box.simple {
    margin-top: 70px;
  }

  .detail-box.simple .indicator-box {
    border-radius: 40px 40px 40px 40px;
  }

  /* Activities */

  .activities h3 {
    margin-top: 0;
    color: #84754E;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  .activities h3.message {
    margin-top: 45px;
    font-size: 19px;
    font-weight: bold;
  }

  .activities .card-title {
    text-align: center;
    font-size: 13px !important;
    font-weight: bold;
    color: #84754E;
  }

  .activities .icon {

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 55px;
  }

  .activities .icon.price {
    background-image: url("/static/images/report/price_icon.png");
  }

  .activities .icon.visits {
    background-image: url("/static/images/report/visits_icon.png");
  }

  .activities .icon.email {
    background-image: url("/static/images/report/email_icon.png");
  }

  .activities .icon.presentation {
    background-image: url("/static/images/report/presentations_icon.png");
  }

  .activities .icon.callPhones {
    background-image: url("/static/images/report/cellPhone_icon.png");
  }

  /* right operation / modal label */
  .detail-box .col-md-2 {
    padding: 0;
  }

  .detail-box .col-md-2 p {
    top: 113px;
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
  }

  .detail-box .col-md-2 p {
    top: 197px;
    left: 0;
    padding-left: 58px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 23px;
    font-weight: 200;
    position: relative;
    background-image: url("/static/images/report/carret.png");
    background-position: -9px center;
    background-repeat: no-repeat;

  }

  .chart-label .medium {
    font-size: 10px
  }

  .card-legend .small {
    font-size: 11px !important;
  }

  .card-title.info {
    margin-top: 59px;
  }

  .card-title.center.info {
    margin-top: 134px;
  }

  .card-title.info, .card-title.info b, .card-legend.info {
    font-size: 15px;
  }

  .summary-box {
    margin-top: 39px;
  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }
</style>
